<template>
  <div class="content">
    <h2 class="title">
      {{ federation.title }}
    </h2>

    <div class="column is-half-desktop">
      <TableRow :value="federation.code ? federation.code : ''" title="Код" />
      <TableRow
        :value="federation.siteUrl ? federation.siteUrl : ''"
        :is-link="true"
        title="Сайт"
      />
    </div>

    <EditButtons @edit="handleEdit" @remove="handleRemove" />
  </div>
</template>

<script>
import { Resource } from "@/common/const/common";
import InternationalFederationForm from "./InternationalFederationForm";
import { AppRoute } from "@/common/const/route";
import TableRow from "@/common/components/AppTableRow";
import EditButtons from "@/common/components/AppEditButtons";
import {
  DELETE_ERROR,
  DELETE_SUCCESS,
  UNKNOWN_ERROR,
} from "@/common/const/message";
import breadcrumbs from "@/common/mixins/breadcrumbs";

export default {
  name: "InternationalFederationShow",

  components: { TableRow, EditButtons },

  mixins: [breadcrumbs],

  data() {
    return {
      federation: { title: "" },
      isShowForm: false,
    };
  },

  async mounted() {
    try {
      this.federation = await this.$api[
        Resource.INTERNATIONAL_FEDERATIONS
      ].getOne(this.$route.params.id);
      if (!this.federation) {
        await this.$router.push(AppRoute.NOT_FOUND);
        return;
      }

      this.$setBreadcrumbTitle(this.federation.title);
      document.tite = this.federation.title;
    } catch (e) {
      this.$notifier.error(UNKNOWN_ERROR);
      await this.$router.go(-1);
    }
  },

  methods: {
    async closeForm(isUpdated = true) {
      if (isUpdated) {
        this.federation = await this.$api[
          Resource.INTERNATIONAL_FEDERATIONS
        ].getOne(this.federation.id);
      }
    },

    async handleEdit() {
      this.$buefy.modal.open({
        parent: this,
        component: InternationalFederationForm,
        props: { federation: this.federation },
        events: { close: this.closeForm },
        hasModalCard: true,
      });
    },

    async handleRemove() {
      this.$buefy.dialog.confirm({
        title: "Удаление федерации",
        class: "is-size-4",
        message: `"${this.federation.title}" будет удалена!!!`,
        cancelText: "Отмена",
        confirmText: "Удалить",
        hasIcon: true,
        type: "is-danger",
        onConfirm: async () => {
          try {
            await this.$api[Resource.INTERNATIONAL_FEDERATIONS].delete(
              this.federation.id
            );
            this.$notifier.success(DELETE_SUCCESS);
            await this.$router.push(AppRoute.INTERNATIONAL_FEDERATIONS);
          } catch (e) {
            this.$notifier.error(e.response.data.message || DELETE_ERROR);
          }
        },
      });
    },
  },
};
</script>
