var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var invalid = ref.invalid;
return [_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('section',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" "+_vm._s(_vm.federation.id ? "Редактирование федерации" : "Добавить федерацию")+" ")])]),_c('section',{staticClass:"modal-card-body"},[_c('ValidationProvider',{attrs:{"rules":"required|min:3","name":"Название","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('b-field',{attrs:{"label":"Название","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:2|max:10","name":"Код","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('b-field',{attrs:{"label":"Код","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"url","name":"Адрес сайта","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('b-field',{attrs:{"label":"Сайт","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"url"},model:{value:(_vm.siteUrl),callback:function ($$v) {_vm.siteUrl=$$v},expression:"siteUrl"}})],1)}}],null,true)})],1),_c('footer',{staticClass:"modal-card-foot"},[_c('AppFormButtons',{attrs:{"is-submit-disabled":invalid,"is-show-reset":false},on:{"send":function($event){validate().then(_vm.handleSubmit)},"close":_vm.handleClose}})],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }