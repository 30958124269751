<template>
  <ValidationObserver
    v-slot="{ validate, invalid }"
    ref="form"
    tag="form"
    @submit.prevent="handleSubmit"
  >
    <div class="modal-card" style="width: auto">
      <section class="modal-card-head">
        <p class="modal-card-title">
          {{
            federation.id ? "Редактирование федерации" : "Добавить федерацию"
          }}
        </p>
      </section>

      <section class="modal-card-body">
        <ValidationProvider rules="required|min:3" name="Название" slim>
          <b-field
            slot-scope="{ errors, valid }"
            label="Название"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-input v-model="title" type="text" />
          </b-field>
        </ValidationProvider>

        <ValidationProvider rules="required|min:2|max:10" name="Код" slim>
          <b-field
            slot-scope="{ errors, valid }"
            label="Код"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-input v-model="code" type="text" />
          </b-field>
        </ValidationProvider>

        <ValidationProvider rules="url" name="Адрес сайта" slim>
          <b-field
            slot-scope="{ errors, valid }"
            label="Сайт"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-input v-model="siteUrl" type="url" />
          </b-field>
        </ValidationProvider>
      </section>

      <footer class="modal-card-foot">
        <AppFormButtons
          :is-submit-disabled="invalid"
          :is-show-reset="false"
          @send="validate().then(handleSubmit)"
          @close="handleClose"
        />
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Resource } from "@/common/const/common";
import AppFormButtons from "@/common/components/AppFormButtons";
import { SAVE_ERROR, SAVE_SUCCESS } from "@/common/const/message";

export default {
  name: "InternationalFederationForm",
  components: {
    ValidationObserver,
    ValidationProvider,
    AppFormButtons,
  },
  props: {
    federation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      title: "",
      code: "",
      siteUrl: "",
    };
  },
  created() {
    this.title = this.federation.title;
    this.code = this.federation.code;
    this.siteUrl = this.federation.siteUrl || "";
  },
  methods: {
    async handleClose() {
      this.$emit("close", false);
    },
    async handleSubmit() {
      const federation = {
        title: this.title,
        code: this.code.toUpperCase(),
        siteUrl: this.siteUrl,
      };

      try {
        if (this.federation.id) {
          await this.$api[Resource.INTERNATIONAL_FEDERATIONS].put(
            federation,
            this.federation.id
          );
        } else {
          await this.$api[Resource.INTERNATIONAL_FEDERATIONS].post(federation);
        }

        this.$notifier.success(SAVE_SUCCESS);
        this.$emit("close");
      } catch (e) {
        if (e.statusCode) {
          this.$notifier.error("Дублирование записей, проверьте поля формы");
        } else {
          this.$notifier.error(SAVE_ERROR);
        }
      }
    },
  },
};
</script>
